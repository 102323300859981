exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accept-reject-quotes-index-js": () => import("./../../../src/pages/accept-reject-quotes/index.js" /* webpackChunkName: "component---src-pages-accept-reject-quotes-index-js" */),
  "component---src-pages-authentication-index-js": () => import("./../../../src/pages/authentication/index.js" /* webpackChunkName: "component---src-pages-authentication-index-js" */),
  "component---src-pages-cancel-order-index-js": () => import("./../../../src/pages/cancel-order/index.js" /* webpackChunkName: "component---src-pages-cancel-order-index-js" */),
  "component---src-pages-create-order-index-js": () => import("./../../../src/pages/create-order/index.js" /* webpackChunkName: "component---src-pages-create-order-index-js" */),
  "component---src-pages-datalibrary-api-index-js": () => import("./../../../src/pages/datalibrary-api/index.js" /* webpackChunkName: "component---src-pages-datalibrary-api-index-js" */),
  "component---src-pages-datalibrary-api-list-purchased-datasets-index-js": () => import("./../../../src/pages/datalibrary-api/list-purchased-datasets/index.js" /* webpackChunkName: "component---src-pages-datalibrary-api-list-purchased-datasets-index-js" */),
  "component---src-pages-datalibrary-api-single-dataset-index-js": () => import("./../../../src/pages/datalibrary-api/single-dataset/index.js" /* webpackChunkName: "component---src-pages-datalibrary-api-single-dataset-index-js" */),
  "component---src-pages-delivery-index-js": () => import("./../../../src/pages/delivery/index.js" /* webpackChunkName: "component---src-pages-delivery-index-js" */),
  "component---src-pages-get-single-order-index-js": () => import("./../../../src/pages/get-single-order/index.js" /* webpackChunkName: "component---src-pages-get-single-order-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-mapbox-index-js": () => import("./../../../src/pages/integrations/mapbox/index.js" /* webpackChunkName: "component---src-pages-integrations-mapbox-index-js" */),
  "component---src-pages-list-order-index-js": () => import("./../../../src/pages/list-order/index.js" /* webpackChunkName: "component---src-pages-list-order-index-js" */),
  "component---src-pages-order-api-index-js": () => import("./../../../src/pages/order-api/index.js" /* webpackChunkName: "component---src-pages-order-api-index-js" */),
  "component---src-pages-testing-environment-index-js": () => import("./../../../src/pages/testing-environment/index.js" /* webpackChunkName: "component---src-pages-testing-environment-index-js" */),
  "component---src-pages-update-order-index-js": () => import("./../../../src/pages/update-order/index.js" /* webpackChunkName: "component---src-pages-update-order-index-js" */),
  "component---src-pages-versions-index-js": () => import("./../../../src/pages/versions/index.js" /* webpackChunkName: "component---src-pages-versions-index-js" */)
}

